import React, { useState,useEffect } from "react";
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Raison from "./raison.js";
import Prenom from "./nomPrenom.js";
import Echange from "./Echange.js";
import Dotation from "./Dotation.js";
import Retour from "./Retour.js";
import HeadphonesIcon from '@mui/icons-material/Headphones';
import { styled } from '@mui/system';

//variable qui permet de gerer l'affichage de l'image
const ImageContainer = styled('img')({
  width: '300px',
  height: 'auto',
});

//fonction qui permet de recuperer la date et l'heure en string 
function stringdate() {
    var date = new Date();
    var jour = date.getDate();
    var mois = date.getMonth() + 1;
    var annee = date.getFullYear();
    var heure = date.getHours();
    var minute = date.getMinutes();
    if(minute < 10) minute = "0" + minute;
    if(heure < 10) heure = "0" + heure;
    if(jour < 10) jour = "0" + jour;
    if(mois < 10) mois = "0" + mois;
    return jour + "/" + mois + "/" + annee + " " + heure + ":" + minute;
}

//fonction qui permet de gerer l'affichage de la page principale et le switch entre les differentes possibilités (Echange,Dotation,Perte,Retour)
function Main() {
    const [Data, setData] = useState([]);
    const [nom_Prenom, setNom_Prenom] = useState("");
    const [id, setId] = useState(0);
    const [code, setCode] = useState("");
    const [raison, setRaison] = useState("");
    const [date,setDate] = useState(stringdate());
    const [casqueRendu, setCasqueRendu] = useState("");
    const [casqueDote, setCasqueDote] = useState("");
    const [typeCasque, setTypeCasque] = useState(0); //
    const [naturePanne, setNaturePanne] = useState(new Array(4).fill(0)); //
    const [commentaire, setCommentaire] = useState("");
    const [probleme, setProbleme] = useState(false);

    console.log(naturePanne);

    //permet de recuperer les données de la bdd sur les personnes et de les mettre dans la variable Data
    useEffect(() => {
      const fetchData = async () => {
        try {
          let url = '/php/Data.php';
          if(window.location.hostname === "localhost" || window.location.port === "3000"){
            url = 'http://localhost:80/php/Data.php';
          }
          const response = await fetch(url);
          let jsonData = await response.json();
          setData(jsonData);
        } catch (error) {
          console.error('Erreur lors de la récupération des données de la bdd:', error);
        }
      };
    
      fetchData();
    }, []);

    //permet de reinitialiser les variables a chaque fois que l'utilisateur change de raison
    const handleReset = () => {
      setCasqueRendu("");
      setCasqueDote("");
      setTypeCasque(0);
      setNaturePanne(new Array(4).fill(0));
      setCommentaire("");
    }

    //permet de verifier que l'utilisateur a bien rempli tous les champs et de les envoyer dans la bdd historique/casque et probleme si tout est bon
    const handleValidate = () => {
      if(nom_Prenom === ""){
        alert("Veuillez renseigner un nom et un prénom");
        return;
      }
      else if(raison === ""){ // Raison
        alert("Veuillez renseigner une raison");
        return;
      }
      else if(raison === 1){ //Echange
        if(casqueRendu === ""){
          alert("Veuillez renseigner un casque rendu");
          return;
        }
        if(casqueDote === ""){
          alert("Veuillez renseigner un casque pris");
          return;
        }
      }
      else if(raison === 2 || raison === 3){ //Dotation ou Perte
        if(casqueDote === ""){
          alert("Veuillez renseigner un casque pris");
          return;
        }
      }
      else if(raison === 4){ //Retour
        if(casqueRendu === ""){
          alert("Veuillez renseigner un casque rendu");
          return;
        }
      }
      setDate(stringdate());
      const envoie = {
        id: id,
        nom_Prenom: nom_Prenom,
        code: code,
        raison: raison,
        date: date,
        casqueRendu: casqueRendu,
        casqueDote: casqueDote,
        typeCasque: typeCasque,
        naturePanne: naturePanne,
        commentaire: commentaire,
        nonf: probleme
      }
      console.log(envoie);
      let url = '/php/AjoutHistoBDD.php';
      if(window.location.hostname === "localhost"){
        url = 'http://localhost:80/php/AjoutHistoBDD.php';
      }
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(envoie)
      };
      fetch(url, requestOptions)
        .then(data => {
          window.location.href = 'Confirmation.html';
        });
    }

    var tab = []; //variable pour stocker l'affichage selon la raison

    //permet de switch entre les differentes raisons 1=Echange, 2=Dotation, 3=Perte, 4=Retour
    if(raison === 1){
        tab.push(
          <Echange casqueRendu={casqueRendu} setCasqueRendu={setCasqueRendu} 
                    typeCasque={typeCasque} setTypeCasque={setTypeCasque} probleme={probleme} setProbleme={setProbleme}
                    naturePanne={naturePanne} setNaturePanne={setNaturePanne} commentaire={commentaire} 
                    setCommentaire={setCommentaire} casqueDote={casqueDote} setCasqueDote={setCasqueDote}
                    
            />
        )
    }
    else if(raison === 2 || raison ===3){
      tab.push(
        <Dotation casqueDote={casqueDote}setCasqueDote={setCasqueDote} />
      )
    }
    else if(raison === 4){
      tab.push(
        <Retour casqueRendu={casqueRendu} setCasqueRendu={setCasqueRendu} probleme={probleme} setProbleme={setProbleme} 
                typeCasque={typeCasque} setTypeCasque={setTypeCasque} 
                naturePanne={naturePanne} setNaturePanne={setNaturePanne} commentaire={commentaire} 
                setCommentaire={setCommentaire}
        />
      )
    }
  
    return (
        <div id="main_page">
            <div id="Header">
                <h1>Gestion des casques <HeadphonesIcon fontSize="large"/></h1>
                <ImageContainer src= "casques.jpg" alt="casque HME 46"/>
            </div>
            <div id="main">
              <Prenom setNom_Prenom={setNom_Prenom} Data={Data} setId={setId} setCode={setCode} />
              <Raison raison={raison} setRaison={setRaison} handleReset={handleReset} />
                {tab}
              <Button onClick={handleValidate} variant="contained" endIcon={<SendIcon />} sx={{marginTop:"20px"}}>
                Valider
              </Button>
            </div>
        </div>
    );
}

export default Main; 
