import { FormControl, Typography, FormControlLabel, TextField, Box, Radio, RadioGroup } from "@mui/material";
import Tab from "./Tab.js";

function Main(props){
    const {typeCasque, setTypeCasque, naturePanne, setNaturePanne, commentaire, setCommentaire} = props;

    const type = ["Micro-casque", "Micro-main"];
    const panne = ["Permanente", "Récurrent", "A l'émission", "A la réception"];
    const texte = ["Type de casque :", "Nature de la panne :","Commentaires :"];

    const handleTypeChange = (event) => {
        console.log(event.target.value);
        setTypeCasque(Number(event.target.value));
    };    

    const handleCommentChange = (event) => {
        console.log(event.target.value);
        setCommentaire(event.target.value);
    };

    const tab = <Tab checked={naturePanne} setChecked={setNaturePanne} checkbox={panne}/>;

    return (
        <>
            <Typography variant="h6" gutterBottom component="div" key={2} sx={{fontWeight: 'bold', marginTop:"10px"}}>
                {texte[0]}
            </Typography>

            <FormControl component="fieldset">
                <RadioGroup row aria-label="type" name="type" value={typeCasque} onChange={handleTypeChange}>
                    <FormControlLabel value={0} control={<Radio />} label={type[0]} />
                    <FormControlLabel value={1} control={<Radio />} label={type[1]} />
                </RadioGroup>
            </FormControl>

            <Typography variant="h6" gutterBottom component="div" key={4} sx={{fontWeight: 'bold', marginTop:"10px"}}>
                {texte[1]}
            </Typography>
            
            <Box sx={{ marginTop: "10px", marginLeft: "20%"}} key={5}>
                {tab}
            </Box>

            <Typography variant="h6" gutterBottom component="div" key={6} sx={{fontWeight: 'bold', marginTop:"10px"}}>
                {texte[2]}
            </Typography>

            <Box sx={{ marginTop: "10px", marginLeft: "20%", marginRight: "20%"}} key={7}>
                <TextField 
                    className='Commentaire'
                    label={"Commentaire"}
                    multiline
                    variant="outlined"
                    value={commentaire}
                    onChange={handleCommentChange}
                    fullWidth
                />
            </Box>
        </>
    )

}

export default Main;